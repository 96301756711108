<template>
  <!-- It is necessary for v-card-actions to have a transparent background -->
  <v-card :color="!fillHeaderWithActions ? 'transparent' : ''" :flat="(!fillHeaderWithActions || fillHeader || flat)">
    <!-- It deals with between white or transparent background -->
    <v-card :color="!fillHeader ? 'transparent' : ''" :flat="!fillHeader || fillHeaderWithActions||flat">
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-icon
          v-if="expandable"
          @click="expanded = !expanded"
        >
          {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-card-title>
      <!-- It deals with the form content -->
      <v-expand-transition>
        <v-card v-if="!expandable||expanded" :disabled="formProcessing" :flat="fillHeader||fillHeaderWithActions||flat">
          <slot></slot>
        </v-card>
      </v-expand-transition>
    </v-card>
    <!-- The default action for the component -->
    <v-card-actions v-if="!hideDefaultActions" class="pb-4" :class="!fillHeaderWithActions ? 'px-0 mt-2' : 'px-4'">
      <v-btn
          block
          color="primary"
          :depressed="fillHeaderWithActions"
          large
          :loading="formProcessing"
          @click="save"
      >
        Salvar
      </v-btn>
    </v-card-actions>
    <custom-snackbar ref="snackbar" />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: 'MyForm',

  props: {
    expandable: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    fillHeader: {
      type: Boolean,
      default: false,
    },
    fillHeaderWithActions: {
      type: Boolean,
      default: false,
    },
    hideDefaultActions: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: '',
    },
    submitRedirect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'List Name',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      formProcessing: false,
      internalValue: this.value,
    }
  },
  watch: {
    value (newValue) {
      this.internalValue = newValue
    },

    internalValue (newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    async init () {
      if (this.id !== false) {
        this.internalValue = (await ApiService.get(`${this.resource}/${this.id}`)).data
      }
    },

    async save() {
      this.formProcessing = true

      this.$emit('validate', [])

      try {
        if (this.id === false) {
          await ApiService.post(this.resource, this.internalValue)
        } else {
          await ApiService.put(`${this.resource}/${this.id}`, this.internalValue)
        }

        this.$refs.snackbar.show(
            'Tudo certo!',
            'Operação realizada com sucesso',
            'success',
            '2000'
        )
        
        if (this.submitRedirect !== null) {
          setTimeout(() => {
            this.$router.push({ path: this.submitRedirect })
          }, 1000)
        }

        this.$emit('success')
      } catch (e) {
        if (e.response) {
          if (e.response.status === 422) {
            this.$emit('validate', e.response.data.errors)

            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          } else if (e.response.data.message) {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          } else {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        } else {
          this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      this.formProcessing = false
    },
  },

  mounted () {
    window.scrollTo(0, 0)

    this.id = this.$route.params.id ?? false

    this.init()
  },
}
</script>