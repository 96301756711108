import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":!_vm.fillHeaderWithActions ? 'transparent' : '',"flat":(!_vm.fillHeaderWithActions || _vm.fillHeader || _vm.flat)}},[_c(VCard,{attrs:{"color":!_vm.fillHeader ? 'transparent' : '',"flat":!_vm.fillHeader || _vm.fillHeaderWithActions||_vm.flat}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),(_vm.expandable)?_c(VIcon,{on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v(" "+_vm._s(_vm.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_vm._e()],1),_c(VExpandTransition,[(!_vm.expandable||_vm.expanded)?_c(VCard,{attrs:{"disabled":_vm.formProcessing,"flat":_vm.fillHeader||_vm.fillHeaderWithActions||_vm.flat}},[_vm._t("default")],2):_vm._e()],1)],1),(!_vm.hideDefaultActions)?_c(VCardActions,{staticClass:"pb-4",class:!_vm.fillHeaderWithActions ? 'px-0 mt-2' : 'px-4'},[_c(VBtn,{attrs:{"block":"","color":"primary","depressed":_vm.fillHeaderWithActions,"large":"","loading":_vm.formProcessing},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1):_vm._e(),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }